<template>
  <v-form ref="form">
    <v-container fluid>
      <v-row align="center">
        <v-col class="d-flex" cols="12" sm="6">
          <v-autocomplete
            v-model="$parent.item.underwriterId"
            :items="ctx.src.org.byType('1')"
            :label="'Страховая компания'"
            item-text="fullName"
            item-value="id"
            required
          ></v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-autocomplete
            v-model="$parent.item.organizationId"
            :items="ctx.src.org.byType('3')"
            :label="'Компания'"
            item-text="fullName"
            item-value="id"
            required
          ></v-autocomplete>
        </v-col>

        <v-col class="d-flex" cols="12" sm="6">
          <v-autocomplete
            v-model="$parent.item.categoryId"
            :items="ctx.src.categories.items"
            :label="'Категория'"
            item-text="name"
            item-value="id"
            required
          ></v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-autocomplete
            v-model="$parent.item.serviceId"
            :items="ctx.src.services.byCategory($parent.item.categoryId)"
            :label="'Услуга'"
            item-text="name"
            item-value="id"
            required
          ></v-autocomplete>
        </v-col>

        <v-col class="d-flex" cols="12" sm="6">
          <v-text-field v-model="$parent.item.amount" label="Сумма" type="number"></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-text-field v-model="$parent.item.quantity" label="Количество" type="number"></v-text-field>
        </v-col>

        <v-col class="d-flex" cols="12" sm="6">
          <v-text-field v-model="$parent.item.startDate" label="Действует с" type="date"></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-text-field v-model="$parent.item.endDate" label="Действует по" type="date"></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ctx from "@/ctx";

export default {
  data: () => ({
    ctx
  }),
  created() {
    this.$parent.detail = this;
  }
};
</script>

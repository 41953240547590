<template>
  <v-btn icon color="primary" :loading="loading" @click="dialog = true">
    <v-icon>cloud_upload</v-icon>
    <v-dialog v-model="dialog" max-width="600px">
      <template>
        <v-card>
          <v-card-title>
            <span class="headline">Выберите файл...</span>
          </v-card-title>
          <v-card-text>
            <v-form>
              <v-row>
                <v-col cols="12" md="12">
                  <v-file-input
                    v-model="file"
                    accept=".xls, .xlsx, .xlsm"
                    label="Файл"
                    show-size
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text @click="closeDialog">
              Отмена
            </v-btn>
            <v-btn :disabled="!file" color="primary" text @click="parseFile">
              Ок
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-dialog
      v-model="dialogImport"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar class="my-bar">
          <v-toolbar-title>{{ fileName }}</v-toolbar-title>
<!--          <v-btn icon :disabled="!valid || items.length == 0" @click="save">
            <v-icon>save</v-icon>
          </v-btn>-->
          <SaveButton
          :additional-rules="!valid || items.length === 0"
          :handler="save"
          >

          </SaveButton>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="my-content">
          <v-form ref="form" v-model="valid" style="height: 100%">
            <v-simple-table fixed-header dense style="height: 100%">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th></th>
                    <th>№</th>

                    <th class="text-left" style="min-width: 15vw">
                      Поставщик
                    </th>
                    <th class="text-left" style="min-width: 15vw">
                      Категория
                    </th>
                    <th class="text-left" style="min-width: 15vw">
                      Мед. услуга
                    </th>
                    <th class="text-left">
                      Количество
                    </th>
                    <th class="text-left">
                      Сумма
                    </th>
                    <th class="text-left">
                      Дата начала
                    </th>
                    <th class="text-left">
                      Дата окончания
                    </th>
                  </tr>
                </thead>
                <tbody v-for="(item, i) in items" :key="i">
                  <PriceImportItem :item="item" :index="i"></PriceImportItem>
                </tbody>
              </template>
            </v-simple-table>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogException" width="400">
      <v-card>
        <v-card-title>
          <span style="color: red">Ошибка</span>
        </v-card-title>
        <v-card-text>
          <span>Выбран файл неверного формата</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="closeDialog">
            Ок
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
import ctx from "@/ctx";
import vm2js from "../../utils/vm2js";
import fetch from "../../utils/fetch";
import PriceImportItem from "./PriceImportItem";
import dateIsoFilter from "../../filters/dateIso.filter";
import js2vm from "../../utils/js2vm";
import SaveButton from "@/components/ButtonFacade";

let Item = function(d) {
  let self = this;

  self.startDate2vm = function(val) {
    return dateIsoFilter(val);
  };
  self.endDate2vm = function(val) {
    return dateIsoFilter(val);
  };

  self.messages = [];
  if(d.newService) self.messages.push('Новая услуга');
  else self.messages.push('Существующая услуга')

  js2vm(self, d);
};

export default {
  props: {
    vm: Object
  },
  data: () => ({
    ctx,
    items: [],
    valid: false,
    file: undefined,
    dialog: false,
    dialogImport: false,
    dialogException: false,
    fileName: undefined,
    loading: false
  }),
  methods: {
    validate() {
      if (this.$refs.form) this.$refs.form.validate();
    },
    save: async function() {
      if (!this.valid) return;
      const res = await fetch.post(
        "/api/accounting/price-list/save-list",
        vm2js(this.items)
      );
      if (res) {
        this.vm.init();
        this.closeDialog();
      }
    },
    closeDialog() {
      this.dialog = false;
      this.dialogImport = false;
      this.dialogException = false;
      this.file = [];
      this.fileName = undefined;
    },
    async parseFile() {
      this.loading = true;
      this.dialog = false;
      this.items = [];
      let res = await fetch.upload(
        "/api/accounting/price-list/parse-file",
        this.file
      );

      if (res) {
        if (res.length !== 0) {
          this.dialogImport = true;

          res.forEach(element => {
            if (element.newService) {
              ctx.src.services.items.push({
                id: element.serviceId,
                name: element.serviceString
              });
            }
            this.items.push(new Item(element));
            this.validate();
          });
        } else {
          this.dialogException = true;
        }
      } else this.dialogException = true;
      this.loading = false;
      this.fileName = this.file.name;
      this.file = undefined;
    }
  },
  mounted() {
    this.items = [];
  },
  components: {
    SaveButton,
    PriceImportItem
  }
};
</script>

<template>
  <div class="view-wrapper">
    <div class="view">
      <v-toolbar class="my-bar">
        <v-dialog v-model="dialog" max-width="500px" scrollable>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>filter_alt</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              <span class="headline">Фильтр</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="filter.organizationId"
                      :items="ctx.src.org.byType(['3', '4'])"
                      :label="'Организация'"
                      item-text="fullName"
                      item-value="id"
                      clearable
                      @keydown.enter="fetchFiltered"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="filter.underwriterId"
                      :items="ctx.src.org.byType('1')"
                      :label="'Страховая'"
                      item-text="fullName"
                      item-value="id"
                      clearable
                      @keydown.enter="fetchFiltered"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="filter.categoryId"
                      :items="ctx.src.categories.items"
                      :label="'Категория услуг'"
                      item-text="name"
                      item-value="id"
                      clearable
                      @keydown.enter="fetchFiltered"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="filter.serviceId"
                      :items="
                        filter.categoryId
                          ? ctx.src.services.byCategory(filter.categoryId)
                          : ctx.src.services.list()
                      "
                      :label="'Услуга'"
                      item-text="name"
                      item-value="id"
                      clearable
                      @keydown.enter="fetchFiltered"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="filter.date"
                      label="Актуальные на дату"
                      type="date"
                      required
                      @keydown.enter="fetchFiltered"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn text @click="resetFilter">
                Сбросить
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text @click="dialog = false">
                Отмена
              </v-btn>
              <v-btn color="primary" text @click="fetchFiltered">
                Ок
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn icon @click="init">
          <v-icon>refresh</v-icon>
        </v-btn>
        <v-btn v-if="rightFilter('w')" icon @click="add">
          <v-icon>add</v-icon>
        </v-btn>
        <v-btn
          v-if="rightFilter('d')"
          icon
          :disabled="!item || !item.id"
          @click="rem"
        >
          <v-icon>remove</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <PriceImport v-if="rightFilter('w')" :vm="this"></PriceImport>
        <v-btn icon color="primary" :loading="loading" @click="exp">
          <v-icon>cloud_download</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        :single-select="true"
        item-key="id"
        show-select
        hide-default-footer
        :loading="loading"
        loading-text="Загрузка... Пожалуйста подождите"
        fixed-header
        @click:row="rowClick"
      >
        <template v-slot:item.visitDate="{ item }">
          <span>{{ item.visitDate | dateLocalFilter }}</span>
        </template>
        <template v-slot:item.actionDate="{ item }">
          <span>{{ item.actionDate | dateLocalFilter }}</span>
        </template>
      </v-data-table>
    </div>
    <div v-if="item" class="view">
      <v-toolbar class="my-bar">
<!--        <v-btn v-if="rightFilter('w')" icon @click="save">
          <v-icon>save</v-icon>
        </v-btn>-->
        <SaveButton
        :handler="save"
        ></SaveButton>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <div class="my-content">
        <Detail></Detail>
      </div>
    </div>
  </div>
</template>

<script>
import fetch from "../utils/fetch";
import Detail from "../components/prices/Detail";
import dateIsoFilter from "../filters/dateIso.filter";
import js2vm from "../utils/js2vm";
import ctx from "@/ctx";
import dateLocalFilter from "../filters/dateLocal.filter";
import vm2js from "../utils/vm2js";
import PriceImport from "../components/prices/PriceImport";
import rightFilter from "../filters/right.filter";
import SaveButton from "@/components/ButtonFacade";

let Filter = function() {
  let self = this;

  self.organizationId = undefined;
  self.underwriterId = undefined;
  self.categoryId = undefined;
  self.serviceId = undefined;

  self.date = dateIsoFilter(new Date());

  self.insuredId = undefined;
};

let Item = function(d) {
  let self = this;

  d.organization = d.organizationId;
  d.startDate4List = d.startDate;
  d.endDate4List = d.endDate;
  d.category = d.categoryId;
  d.service = d.serviceId;

  self.underwriter2vm = function(val) {
    return ctx.src.org.first(val);
  };
  self.organization2vm = function(val) {
    return ctx.src.org.first(val);
  };

  self.startDate2vm = function(val) {
    return dateIsoFilter(val);
  };
  self.endDate2vm = function(val) {
    return dateIsoFilter(val);
  };

  self.startDate4List2vm = function(val) {
    return dateLocalFilter(val);
  };
  self.endDate4List2vm = function(val) {
    return dateLocalFilter(val);
  };

  self.category2vm = function(val) {
    return ctx.src.categories.get(val);
  };
  self.service2vm = function(val) {
    return ctx.src.services.get(val);
  };

  js2vm(self, d);
};

export default {
  data: () => ({
    ctx,
    rightFilter,
    currentPage: 0,
    totalPages: 0,
    selected: [],
    loading: false,
    headers: [
      { text: "Компания", sortable: false, value: "organization.fullName" },
      { text: "Категория", sortable: false, value: "category.name" },
      { text: "Услуга", sortable: false, value: "service.name" },
      { text: "Сумма", sortable: false, align: "right", value: "amount" },
      {
        text: "Количество",
        sortable: false,
        align: "right",
        value: "quantity"
      },
      { text: "Действует с", sortable: false, value: "startDate4List" },
      { text: "Действует по", sortable: false, value: "endDate4List" }
    ],
    items: [],
    item: undefined,
    filter: new Filter(),
    dialog: false,
    byPages: true
  }),
  watch: {
    selected: function(val) {
      if (val.length === 0) this.item = undefined;
      else this.item = val[0];
    }
  },
  methods: {
    resetFilter() {
      this.filter = new Filter();
    },
    async exp() {
      this.exporting = true;
      const res = await fetch.post(
        "/api/accounting/price-list/export",
        vm2js(this.filter)
      );
      if (res) {
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(await res.blob);
        link.download = res.filename;
        link.click();
      }
      this.exporting = false;
    },
    add() {
      this.close();
      this.selected.push(new Item({}));
    },
    async rem() {
      const res = await fetch.post(
        "/api/accounting/price-list/delete?id=" + this.item.id
      );
      if (res) {
        this.close();
        this.items.splice(this.item, 1);
      }
    },
    async save() {
      const res = await fetch.post(
        "/api/accounting/price-list/save",
        vm2js(this.item)
      );
      if (res) {
        if (this.item.id) {
          Object.assign(
            this.items.find(i => {
              return i.id === this.item.id;
            }),
            this.item
          );
        } else {
          this.item.id = res;
          this.items.unshift(new Item(vm2js(this.item)));
        }
        this.close();
        await ctx.src.prices.init();
      }
    },
    close() {
      this.selected = [];
    },
    handleScroll(e) {
      if (!this.byPages) return;
      if (
        e.target.scrollTop >=
        (e.target.scrollHeight - e.target.offsetHeight) * 0.9
      ) {
        if (this.totalPages - 1 > this.currentPage) {
          this.currentPage++;
          const params = {
            page: this.currentPage
          };
          this.fetchItems(params);
        }
      }
    },
    rowClick(obj, row) {
      if (!row.isSelected) row.select(obj);
      else this.selected = [];
    },
    async fetchItems(params) {
      this.loading = true;
      let res = await fetch.get(
        "/api/accounting/price-list/get-list?page=" + params.page
      );
      if (res.first) this.totalPages = res.totalPages;
      res.content.forEach(r => {
        this.items.push(new Item(r));
      });
      this.loading = false;
    },
    async fetchFiltered() {
      this.close();
      this.loading = true;
      this.items = [];
      this.byPages = false;
      this.dialog = false;
      let res = await fetch.post(
        "/api/accounting/price-list/get-list",
        vm2js(this.filter)
      );
      if (res) {
        res.forEach(element => {
          this.items.push(new Item(element));
        });
      }
      this.loading = false;
    },
    async resetPages() {
      this.byPages = true;
      this.currentPage = 0;
      this.totalPages = 0;
      this.selected = [];
      this.items = [];
    },
    async init() {
      this.loading= true;
      await this.ctx.src.services.init();
      await this.resetPages();
      await this.fetchItems({page: 0});

      this.loading = false;
    }
  },
  mounted() {
    document
      .getElementsByClassName("v-data-table__wrapper")[0]
      .addEventListener("scroll", this.handleScroll);
    this.fetchItems({ page: 0 });
  },
  components: {
    SaveButton,
    Detail,
    PriceImport
  }
};
</script>

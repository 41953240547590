<template>
  <tr>
    <td>
      <v-btn icon @click="rem">
        <v-icon>remove</v-icon>
      </v-btn>
    </td>
    <td>{{ index + 1 }}</td>

    <td>
      <v-autocomplete
        v-model="item.organizationId"
        :items="ctx.src.org.items"
        item-text="fullName"
        item-value="id"
        :rules="requiredRules"
        required
        clearable
      >
      </v-autocomplete>
    </td>
    <td>
      <v-autocomplete
        v-model="item.categoryId"
        :items="ctx.src.categories.items"
        item-text="name"
        item-value="id"
        readonly
      >
      </v-autocomplete>
    </td>
    <td>
      <v-text-field

        v-model="item.serviceName"
        :rules="requiredRules"
        required
        :messages="item.messages"
        :class="serviceClass"
        readonly




      >





      </v-text-field>

    </td>
    <td>
      <v-text-field
        v-model="item.quantity"
        type="number"
        :rules="requiredRules"
        required
      ></v-text-field>
    </td>
    <td>
      <v-text-field
        v-model="item.amount"
        type="number"
        :rules="requiredRules"
        required
      ></v-text-field>
    </td>
    <td>
      <v-text-field v-model="item.startDate" type="date"></v-text-field>
    </td>
    <td>
      <v-text-field v-model="item.endDate" type="date"></v-text-field>
    </td>
  </tr>
</template>

<script>
import ctx from "@/ctx";

export default {
  props: {
    item: Object,
    index: Number
  },
  data: () => ({
    ctx,
    requiredRules: [v => !!v || "Обязательное поле"]
  }),
  watch: {
    "item.serviceId": function(val) {
      this.serviceIdChanged(val);
    }
  },
  computed: {
    serviceClass: function (){
      return this.item.newService ? 'new-service' : '';
    }
  },
  methods: {
    serviceIdChanged(val) {
      if (!val) return (this.item.categoryId = undefined);
      let service = ctx.src.services.get(val);
      if (service) this.item.categoryId = service.categoryId;
    },
    rem() {
      this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.items.splice(
        this.index,
        1
      );
    }
  }
};
</script>

<style lang="scss" scoped>
td {
  padding: 0 2px !important;
}



</style>
<style lang="scss">
.new-service input {
  color: #0096aa !important;
}
</style>
